<template>
  <div>
    <SiteHeader></SiteHeader>

    <div class="topbar text-center">
      <strong class="fs-1">Check your order and confirm.</strong>
      <div class="menu">
        <div class="alink" @click="toCart">My Cart</div>
        <div class="current alink">Orders</div>
        <div class="alink" @click="toAccount">Account</div>
        <div class="alink" @click="toMessages">Messages</div>
        <div class="alink" @click="toAbout">About</div>
      </div>
    </div>

    <div class="orderBar mb-0">
      <div class="container d-flex">
        <a
          :class="{
            current: item.checked,
          }"
          v-for="item in menulist"
          @click="changeStatus(item.status)"
          >{{ item.name }}</a
        >

        <el-dropdown
          trigger="click"
          placement="bottom-start"
          @command="sortchange"
          size="sm"
          class="align-content-center"
        >
          <span>
            {{ currentsort }}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="menu in sortList" :command="menu.id">
              <i
                :class="['bi', 'pe-2', menu.icon]"
                role="img"
                :aria-label="menu.icon"
              ></i>
              {{ menu.name }}</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>

    <div class="container mt-5 mb-5 orderList" v-loading="isLoading">
      <ul>
        <el-empty
          image="svg/empty-order.svg"
          description="No orders placed yet."
          v-if="orderList.length === 0"
        ></el-empty>

        <li v-for="item in orderList" class="oli">
          <div class="alert d-flex justify-content-between align-items-center p-2 mb-2">
            <label>
              <span class="me-3" v-if="item.status > 1">
                <a @click="toOrderDetail(item)" class="text-primary" title="Click to view the invoice" >Order No.# {{
                  item.orderno
                }}</a>
              </span>
              <span class="me-3" v-else>Order No.# {{item.orderno}}</span>
              <span>Creation Date: {{ item.created_at.substr(0, 10) }}
                <i>{{ item.created_at.substr(11, 8) }}</i></span>
            </label>

            <label>
              <!--订单状态;1:已创建;2:已更新;3:已被确认;4:已付款;5:已发货;6:已完成-->

              <el-button type="danger" size="mini" v-if="item.status === 0" @click="toConfirm(item.orderno)"><i class="bi-file-check"></i> Confirming</el-button>

              <el-button size="mini" v-if="item.status === 1"><i class="bi-hourglass-bottom"></i> Awaiting</el-button>

              <!-- <el-button type="primary" size="mini" v-if="item.status === 2" @click="payOnline(item.orderno)">Pay Airwallex</el-button> -->

              <el-button type="primary" size="mini" v-if="item.status === 2" @click="paypal(item.orderno)" id="pay"><i class="bi-paypal"></i> PayPal</el-button>

              <el-button size="mini" v-if="item.status === 3"><i class="bi-receipt"></i> Paid</el-button>

              <el-button type="warning" size="mini" v-if="item.status === 4" @click="toTrack(item.orderno)"><i class="bi-airplane"></i> Tracking</el-button>

              <el-button size="mini" v-if="item.status === 5"><i class="bi-check2-square"></i> Completed</el-button>

              <el-button size="mini" v-if="item.status > 5">Other</el-button>

            </label>
          </div>

          <div v-for="order in item.order_items" @click="todetail(order.id)">
            <a class="thumb" target="_blank"> <img :src="order.thumb" /></a>

            <div class="cart-c">
              <a class="cart-full" target="_blank">
                <h3 class="mb-3">
                  <span>{{ order.title }}</span>
                </h3>
                <p>
                  <span class="me-3 pr-3"
                    ><i class="bi-upc-scan text-danger"></i>
                    <em class="ps-1">Code:</em
                    ><span class="ps-1">{{ order.code }}</span></span
                  >
                  <span class="me-3 pr-3"
                    ><i class="bi-broadcast text-danger"></i>
                    <em class="ps-1">Dash:</em
                    ><span class="ps-1">{{ order.dash }}</span></span
                  >
                  <span class="last"
                    ><i class="bi-vinyl text-danger"></i>
                    <em class="ps-1">CasePack:</em
                    ><span class="ps-1">{{ order.casepack }}PC</span></span
                  >
                </p>
                <p>
                  <span class="me-3 pr-3"
                    ><i class="bi-front text-warning"></i> <em>Series:</em>
                    {{ order.series }}
                  </span>
                  <span class="last"
                    ><i class="bi-cash-stack text-warning"></i>

                    UnitPrice: {{ order.price }} USD/PC</span
                  >
                </p>
              </a>
            </div>

            <div class="cart-r">
              <section>
                <span class="mb-2">
                  <i class="bi-calendar3 text-success"></i>
                  <abbr
                    ><em class="ps-1">Delivery Date：</em
                    ><i>{{ order.ship_at }}</i></abbr
                  >
                </span>

                <span class="mb-2">
                  <i class="bi-basket text-success"></i>
                  <abbr
                    ><em class="ps-1">Order Quantities：</em
                    ><i>{{ order.nums }} PC</i></abbr
                  >
                </span>

                <span class="mb-2">
                  <i class="bi-cash-stack text-success"></i>
                  <abbr
                    ><em class="ps-1">Sub Amount：</em><em>$</em
                    ><i>{{ order.sub_amount }}</i> USD</abbr
                  >
                </span>
              </section>
            </div>
          </div>
        </li>
      </ul>
    </div>


    <el-dialog title="Invoice" :visible.sync="dialogChangePw">
           <Detail />
    </el-dialog>


    <Foot></Foot>
  </div>
</template>

<script>
import SiteHeader from "@/components/website/SiteHeader.vue";
import Foot from "@/components/website/Foot.vue";
import Detail from "./detail.vue";
import { orderList, pay, paypalpay } from "@/api/index.js";

export default {
  name: "PcWebsiteIndex",

  components: {
    SiteHeader,
    Foot,
  },

  // 0 待确认 1.已创建 2.已确认 3.已付款 4.已发货 5.已完成

  data() {
    return {
      isLoading: false,
      orderList: [],
      currentsort: "Sort by",
      menulist: [
        {
          id: 1,
          status: null,
          name: "All",
          checked: true,
        },
        {
          id: 2,
          status: 0,
          name: "Created",
          checked: false,
        },
        {
          id: 3,
          status: 1,
          name: "Awaiting",
          checked: false,
        },
        {
          id: 4,
          status: 2,
          name: "Confirmed",
          checked: false,
        },
        {
          id: 5,
          status: 3,
          name: "Paid",
          checked: false,
        },
        {
          id: 6,
          status: 4,
          name: "Shipped",
          checked: false,
        },
        {
          id: 7,
          status: 5,
          name: "Completed",
          checked: false,
        },
      ],
      sort: 0,
      sortList: [
        {
          id: 0,
          name: "Default",
          icon: "bi-ui-radios",
        },
        {
          id: 1,
          name: "Date,old to new",
          icon: "bi-sort-up",
        },
        {
          id: 2,
          name: "Date,new to old",
          icon: "bi-sort-down",
        },
      ],
    };
  },

  computed: {
    token: {
      get() {
        return this.$store.state.user.token;
      },
    },
    userinfo: {
      get() {
        return this.$store.state.user.userinfo;
      },
    },

    currentstatus: {
      get() {
        //console.log("---", this.$store.state.user.currentstatus);
        return this.$store.state.user.currentstatus;
      },
    },
  },

  watch: {
    currentstatus: {
      handler(v) {
        // console.log('v',v==='null')
        let newlist = [];
        if (v === "null" || v === null) {
          newlist = this.menulist.map((item) => {
            if (item.status === null) {
              item.checked = true;
            } else {
              item.checked = false;
            }
            return item;
          });
        } else {
          newlist = this.menulist.map((item) => {
            if (item.status === parseInt(v)) {
              item.checked = true;
            } else {
              item.checked = false;
            }

            return item;
          });
        }
        this.$nextTick(() => {
          this.menulist = newlist;
        });
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    // 如果当前路由是订单列表页面
    if (this.$route.path === "/order/list") {
      // 发送请求，但不提交 status 参数
      this.getList();
    }
  },
  methods: {


    payOnline(orderno) {
      const params = {
        orderno: orderno,
      };
      pay(params).then((res) => {
        // console.log(res)
        const url = res.data.url;
        window.open(url, "_blank");
      });
    },

    paypal(orderno) {
      const params = {
        orderno: orderno,
      };
      paypalpay(params).then((res) => {
        // console.log(res)
        const url = res.data.url;
        window.open(url, "_blank");
      });
    },

    sortchange(id) {
      this.sort = id;
      this.currentsort = this.sortList[id].name;
      this.getList();
    },

    changeStatus(status) {
      //更改样式
      //更改状态查询
      const newlist = this.menulist.map((item) => {
        if (status === item.status) {
          item.checked = true;
          this.$store.commit("user/setStatus", item.status);
          // this.currentstatus = item.status
        } else {
          item.checked = false;
        }
        return item;
      });
      this.menulist = newlist;
      //更改状态参数
      this.getList();
    },

    getList() {
      this.isLoading = true;
      let params = {
        sort: this.sort,
      };
      // 判断 this.currentstatus 是否不为 null 和空字符串
      if (this.currentstatus !== null && this.currentstatus !== "null") {
        params.status = this.currentstatus;
      }
      orderList(params)
        .then((res) => {
          this.orderList = res.data.list;
          this.isLoading = false;
        })
        .catch((error) => {
          // 如果出现错误（例如 token 过期），则将用户重定向到登录页面
          console.error('Failed to get order list:', error);
          this.$router.push('/login');
        });
    },

    toCart() {
      this.$router.push("/cart");
    },
    toOrders() {
      this.$router.push("/order/list");
    },
    toAccount() {
      this.$router.push("/account");
    },
    toMessages() {
      this.$router.push("/message");
    },
    toAbout() {
      this.$router.push("/about");
    },
    toOrderDetail(detail) {
      if (detail.status > 1) {
        this.$router.push("/order/detail/" + detail.orderno);
      }
    },
    toConfirm(orderno) {
      this.$router.push("/order/confirm/" + orderno);
    },
    toTrack(orderno) {
      this.$router.push("/order/track/" + orderno);
    },

  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";
.oli {
  .alert {
      border-radius: 0;
      border: 0px none;
      background-color: var(--el-color-info-light-8);
      span {
        font-size: 0.85rem;
        a {
          text-decoration: underline !important;
        }
      }
  }
}
</style>
<style>
.el-button--primary {
  background-color: var(--el-color-primary);
  border-color: var(--el-color-primary);
}
.el-button--primary:hover, 
.el-button--primary:focus {
    background-color: var(--el-color-primary-light-3);
    border-color: var(--el-color-primary-light-3);
    color: var(--el-color-white);
}
.el-button--warning {
  background-color: var(--el-color-warning);
  border-color: var(--el-color-warning);
}
.el-button--warning:hover, 
.el-button--warning:focus {
    background-color: var(--el-color-warning-light-3);
    border-color: var(--el-color-warning-light-3);
    color: var(--el-color-white);
}
.text-primary {
  color: var(--el-color-primary) !important;
}

</style>